// Here you can add other styles
.tableborder{width:100%;}
.tableborder th{border:1px solid #f2f2f2; background: #e5e9ee; padding:5px; }
.tableborder td{border:1px solid #f2f2f2; font-size:15px; font-weight:500 ; padding:5px;}
.upload-drop-zone {
  height: 100px;
  width: 260px;
  border-width: 2px;
  margin-bottom: 20px;
}

/* skin.css Style*/
.upload-drop-zone {
  color: #ccc;
  border-style: dashed;
  border-color: #ccc;
  line-height: 100px;
  text-align: center
}
.upload-drop-zone.drop {
  color: #222;
  border-color: #222;
}

.sidebar .nav-link.active {
    /* color: #fff; */
    background: #007bff !important;
}
.myview:hover {
    /* color: #fff; */
    background: #007bff !important;
}
.input-group-text-reg {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: .975rem;
    font-weight: 500;
    line-height: 1.5;
    color: #5c6873;
    text-align: center;
    white-space: nowrap;
    background-color: #f0f3f5;
    border: 1px solid #e4e7ea;
    border-radius: .25rem;
} 
.btn-primary-renew {
    color: #fff;
    background-color: #f86c6b;
    border-color: #f86c6b;
}
